/* You can add global styles to this file, and also import other style files */
.cdk-overlay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #80808026;
  height: 100%;
}

.hover-danger:hover {
  color: red;
  background: rgb(243, 243, 243);
}
.hover-primary:hover {
  color: #0d6efd;
  background: rgb(222, 241, 255);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
}
ul,li
{
list-style: none;
padding: 0;
margin: 0;
}
body {
  margin: 0;
  padding: 0;
}
main {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  margin-top: 200px;
}
.app-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgb(243, 243, 243);
  display: flex;
}
.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1440px;
}
.table > :not(caption) > * > * {
  background: none;
  padding: 0;
  vertical-align: middle;
}
table .dropdown-menu {
  min-width: 6rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: none;
}
.user-management th,
.user-management td {
  background: white;
}
tr {
  transition: all 1s;
  border-bottom: 1px solid rgb(235, 235, 235);
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}
.form-select,
.form-control {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 2rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}
label {
  font-size: 0.9rem;
  margin-bottom: 5px;
}
.form-footer {
  padding-top: 2rem;
}
.form-footer button a {
color: white;
text-decoration: none;
display: inline-block;
width: 100%;
}

.form-label {
  font-weight: bold;
}


@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
      margin-left: auto;
      flex-grow: 0;
  }
}